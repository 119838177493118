<template>
  <div class="my-phone">
    <div class="input-group">
      <Phone :id="id" :value.sync="state.mobileNum" :placeholder="placeholder" :disabled="state.sent || state.authorized || $store.state.account.mobile"/>
      <div class="input-group-append">
        <button class="btn btn-default font-xs" @click="send()" v-if="!state.authorized">{{ state.sent ? "SMS 재발송" : "SMS 인증" }}</button>
        <button class="btn btn-default gray font-xs" v-else>인증 완료</button>
      </div>
    </div>
    <div class="input-group mt-2" v-if="state.sent && !state.expired">
      <Number :id="`${component.name}Code`" :placeholder="`인증 코드 6자리 (00:${state.expSeconds})`" :value.sync="state.code" :allowZero="true" :noComma="true" :maxlength="6"/>
      <div class="input-group-append">
        <button class="btn btn-default font-xs" @click="authorize()">인증 완료</button>
      </div>
    </div>
    <span class="badge badge-point font-xxs" v-if="state.authorized">인증 완료</span>
  </div>
</template>

<script>
import {defineComponent, nextTick, onUnmounted, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import http from "@/scripts/http";
import Number from "@/components/Number";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "componentPhone";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number, Phone},
  mixins: [mixin],
  props: {
    id: String,
    value: String,
    placeholder: String,
    archiveId: Number,
    applicationId: Number,
    mobileAuth: Boolean,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
      init();
    });

    const state = reactive({
      mobileNum: "",
      sent: false,
      authorized: false,
      expired: false,
      code: "",
      expSeconds: "00",
      archiveId: null,
      applicationId: null,
    });

    let timer;
    //let timer, oldMobileNum;

    const init = () => {
      reset();
      state.authorized = props.mobileAuth;
      state.mobileNum = props.value;
      //oldMobileNum = state.mobileNum;
      state.authorized = props.mobileAuth;
      state.archiveId = props.archiveId;
      state.applicationId = props.applicationId;
    };

    const reset = () => {
      state.sent = false;
      state.authorized = false;
      state.expired = false;
      state.code = "";
    };

    const send = async () => {
      if (!lib.isValidMobileNum(state.mobileNum)) {
        document.getElementById(props.id)?.focus();
        return store.commit("setSwingMessage", "휴대전화 번호가 비어있거나 유효하지 않습니다.");
      }

      const args = {
        mobileNum: state.mobileNum
      };

      // 임시저장
      if (!props.applicationId) {
        const res2 = await http.post(`/api/archives/${props.archiveId}/applications`).catch(httpError());

        if (!res2) {
          return;
        }

        state.applicationId = res2.data.body;
        emit("updateApplicationId", res2.data.body);
      }

      http.post(`/api/archives/${state.archiveId}/applications/${state.applicationId}/mobile-auth`, args).then(() => {
        state.sent = true;
        state.authorized = false;
        state.expired = false;
        state.expSeconds = "59";

        clearInterval(timer);
        store.commit("setSwingMessage", "입력하신 휴대전화 번호로 문자를 발송하였습니다. 확인 후 코드 6자리를 입력해주세요.");

        timer = setInterval(() => {
          const seconds = window.Number(state.expSeconds) - 1;
          state.expSeconds = lib.getNumberWithPadding(seconds);

          if (!seconds) {
            clearInterval(timer);
            reset();
            store.commit("setSwingMessage", "인증 유효 시간이 만료되었습니다.");
          }
        }, 1000);

        nextTick(() => {
          document.getElementById(`${component.name}Code`).focus();
        });
      });
    };

    const authorize = () => {
      const args = {
        code: state.code,
        mobileNum: state.mobileNum
      };

      http.put(`/api/archives/${state.archiveId}/applications/${state.applicationId}/mobile-auth`, args).then(() => {
        clearInterval(timer);
        store.commit("checkAccount", init);
        store.commit("setSwingMessage", "휴대전화 번호 저장 및 인증을 완료하였습니다.");
        emit("authorized", true);
      }).catch(() => {
        state.code = "";
        nextTick(() => {
          document.getElementById(`${component.name}Code`).focus();
        });
      });
    };

    watch(() => state.mobileNum, (next) => {
      emit("update:value", next);
    });

    onUnmounted(() => {
      clearInterval(timer);
    });

    return {component, state, reset, send, authorize};
  }
});
</script>

<style lang="scss" scoped>
.my-phone {
  position: relative;

  .input-group {
    input {
      height: $formHeight;
    }

    .btn {
      border: $px1 solid #ced4da;
      width: $px87;

      &.gray {
        background: #e9ecef;
      }
    }
  }

  > .badge {
    position: absolute;
    top: $px-7;
    right: $px-25;
    border-radius: $px4 $px4 $px4 0;
    z-index: 3;
  }

  @media(max-width: 767px) {
    > .badge {
      right: 0;
      border-radius: $px4 $px4 0 $px4;
    }
  }
}
</style>