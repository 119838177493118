<template>
  <div class="application" :class="{skeleton: !state.ready}">
    <div class="card">
      <div class="card-header">
        <b>{{ state.archive.title }}</b>
        <div class="desc" v-if="state.archive.description">{{ state.archive.description }}</div>
        <div class="desc" v-if="state.archive.managerTel">
          <span>담당자에게 바로 문의하기 : </span>
          <a :href="`tel:${state.archive.managerTel}`">{{ $lib.getPhoneNumberFormat(state.archive.managerTel, true) }}</a>
        </div>
        <div v-if="state.archive.guideUrl" class="desc">
          <a :href="getUrl(state.archive.guideUrl)" target="_blank" rel="noopener noreferrer">{{ state.archive.guideName }} 바로가기</a>
        </div>
        <div class="label" v-if="state.constraint.id">{{ computedConstraintLabel }}</div>
      </div>
      <div class="card-body">
        <div class="status">
          <div class="wrapper">
            <select class="form-control font-sm border-focus-point" v-model="state.step.idx" @change="query(state.step.idx)" :disabled="!state.loaded">
              <option v-for="(s, idx) in state.step.list" :value="idx" :key="idx">
                <template>{{ s.title }}</template>
                <template v-if="$env.device === 'mobile'">({{ idx + 1 }}/{{ state.step.list.length }})</template>
              </option>
            </select>
            <div class="side d-none d-sm-block">
              <span>진행 단계: &nbsp;{{ state.step.idx + 1 }} / {{ state.step.list.length }}</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="wrapper" v-if="state.ready">
            <div class="alert alert-danger font-sm" v-if="state.application.canceled">취소된 신청서입니다.</div>
            <div class="alert alert-danger font-sm" v-else-if="state.application.comment" v-html="computedComment"></div>
            <div class="item" :data-id="item.id" v-for="(item, idx) in state.step.list[state.step.idx].items" :key="item.id">
              <ApplicationItem
                  :item="item"
                  :skeleton="!state.ready"
                  :disabled="!$store.state.account.memberAuth.includes('ADMIN') && (!state.loaded || (state.application.finished && state.application.status !== 'denied') || (state.application.status === 'denied' && item.answers.length > 0 && item.answers[0] && !item.answers[0].reason))"
                  :files="state.application.files[item.id]"
                  :answer="state.application.answers.new[item.id]"
                  :componentNameSuffix="`${item.id}`"
                  :last="state.step.list[state.step.idx].items.length - 1 === idx"
                  :applicationId="state.application.id"
              />
              <span class="reason" v-if="item.answers.length > 0 && item.answers[0].reason">{{ item.answers[0].reason }}</span>
            </div>
          </div>
          <div class="wrapper" v-else>
            <div class="item" :data-id="item.id" v-for="(item, idx) in state.step.list[0].items" :key="'s' + idx">
              <ApplicationItem
                  :item="item"
                  :skeleton="true"
                  :files="state.application.files[item.id]"
                  :answer="state.application.answers.new[item.id]"
                  :componentNameSuffix="`${item.id}`"
              />
            </div>
          </div>
          <div class="mobile-auth wrapper font-sm" v-if="state.step.idx >= state.step.list.length - 1 && !$store.state.account.memberAuth.includes('ADMIN')">
            <div>본인확인</div>
            <ArchivePhone :id="`${component.name}Mobile`" :value.sync="$store.state.account.mobile" placeholder="ex) 01012345678" v-if="state.loaded" :archiveId="state.archive.id" :applicationId="state.application.id"
                          @authorized="authorized(true)" @updateApplicationId="updateApplicationId" :mobileAuth="state.application.mobileAuth"/>

            <input type="text" class="form-control" v-else/>
          </div>
        </div>
        <div class="actions" v-if="$store.state.account.memberAuth.includes('ADMIN') || (!state.application.finished || state.application.status === 'denied')">
          <div class="wrapper">
            <div class="buttons row">
              <div class="col-4" v-if="state.step.idx > 0">
                <button class="btn btn-light" @click="move(state.step.idx - 1)" :disabled="!state.loaded">이전 단계</button>
              </div>
              <div class="col-4">
                <button class="btn btn-light" @click="submit(false, false)" :disabled="!state.loaded">임시 저장</button>
              </div>
              <div :class="[state.step.idx <= 0 ?'col-8':'col-4']">
                <button class="btn btn-point" @click="submit(true, true)" :disabled="!state.loaded" v-if="state.step.idx >= state.step.list.length - 1 && $store.state.account.memberAuth.includes('ADMIN') && state.application.finished">
                  수정하기
                </button>
                <button class="btn btn-point" @click="submit(true, false)" :disabled="!state.loaded" v-else-if="state.step.idx >= state.step.list.length - 1">제출하기</button>
                <button class="btn btn-point" @click="move(state.step.idx + 1)" :disabled="!state.loaded" v-else>다음 단계</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {computed, defineComponent, reactive, set, watch} from "@vue/composition-api";
import ApplicationItem from "@/pages/archive/ApplicationItem";
import router from "@/scripts/router";
import http from "@/scripts/http";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";
import lib from "@/scripts/lib";
import optionTypes from "@/texts/formItemOptionTypes.json";
import MyPhone from "@/components/MyPhone.vue";
import Phone from "@/components/Phone.vue";
import Number from "@/components/Number.vue";
import ArchivePhone from "@/components/ArchivePhone.vue";

function Component(initialize) {
  this.name = "pageArchiveApplication";
  this.initialize = initialize;
}

export default defineComponent({
  components: {ArchivePhone, Number, Phone, MyPhone, ApplicationItem},
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      state.application.id = window.Number(router.app.$route.params.applicationId) || null;
      state.constraint.id = window.Number(router.app.$route.query.constraintId) || null;
      state.step.idx = window.Number(router.app.$route.query.step) || 0;

      state.archive.title = "Wait a moment";
      state.archive.description = "Wait a moment";

      // for skeleton
      state.step.list.push({
        id: 0,
        title: "Wait",
        items: []
      });

      for (let i = 0; i < 7; i += 1) {
        state.step.list[0].items.push({
          id: i,
          answers: [],
          description: "Please Wait a moment",
          title: "Wait a moment",
          type: "text"
        });

        state.application.answers.new[i] = {value: null};
      }

      state.application.id = window.Number(router.app.$route.params.applicationId) || null;

      await http.get(`/api/archives/${router.app.$route.params.name}`).then(({data}) => {
        state.archive = data.body;
      }).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 403:
            window.alert(err?.response?.data.message + " 메인으로 이동합니다.");
            window.location.replace("/");
        }
      }));

      if (state.application.id) {
        return load();
      }

      if (state.archive.id) {
        await http.get(`/api/archives/${state.archive.id}/constraints`).then(({data}) => {
          state.constraint.list = data.body;
        });

        if (state.constraint.list.length) {
          const constraint = state.constraint.list.find(c => c.id === state.constraint.id);

          if (!state.constraint.id || !constraint) {
            window.alert("접속하신 URL에 문제가 있습니다. 관리자에게 문의해주세요.");
            window.location.replace("/archive/applications");
            return;
          }

          // 유효성 체크
          let validMessage = "";

          await http.get(`/api/archives/${state.archive.id}/constraints/${state.constraint.id}/valid`).then(({data}) => {
            switch (data.body) {
              case "duplicate-parent":
                validMessage = `이미 동일한 ${state.archive.constraintParentLabel || "조건"}의 신청 내역이 있습니다. 상세 내용은 회사에 문의해주세요.`;
                break;

              case "duplicate-child":
                validMessage = `이미 동일한 ${state.archive.constraintChildLabel || "조건"}의 신청 내역이 있습니다. 상세 내용은 회사에 문의해주세요.`;
                break;

              case "duplicate-self":
                validMessage = `이미 신청한 항목입니다. 신청 내역을 확인하시려면 우측 상단의 점 세개를 눌러 '나의 신청 내역'으로 이동해주세요.`;
                break;

              case "full":
                validMessage = "신청 가능한 인원이 다 차서 더 이상 신청할 수 없습니다.";
                break;
            }
          });

          if (validMessage) {
            window.alert(validMessage);
            window.history.back();
            return setTimeout(() => {
              window.location.replace("/archive/applications");
            }, 250);
          }
        }

        http.get(`/api/archives/${state.archive.id}/applications`, {constraintId: state.constraint.id}).then(({data}) => {
          if (!data.body.length) {
            return load();
          }

          store.commit("confirm", {
            message: "이미 작성 중인 폼이 있습니다. 이어서 쓰시겠습니까?",
            subMessage: "새로운 폼 작성 시 기존 폼의 내용은 사라집니다.",
            allow() {
              state.application.id = data.body[0].id;
              router.replace(`/archives/${router.app.$route.params.name}/applications/${state.application.id}`);
              load();
            },
            disallow() {
              load();
            }
          });
        });
      }
    });

    const state = reactive({
      ready: false,
      loaded: false,
      archive: {
        id: null,
        name: "",
        description: "",
        constraintFlag: "",
        constraintParentLabel: "",
        constraintChildLabel: "",
        managerTel: "",
        steps: []
      },
      step: {
        idx: 0,
        list: []
      },
      application: {
        id: null,
        finished: false,
        canceled: false,
        status: null,
        items: {},
        answers: {
          new: {},
          old: {},
        },
        files: {},
        mobileAuth: false,
        comment: "",
      },
      constraint: {
        id: 0,
        list: []
      },
    });

    const arrayItemTypes = [
      "file",
      "checkbox",
    ];

    const computedConstraintLabel = computed(() => {
      const results = [];
      const constraintChild = state.constraint.list.find(c => c.id === state.constraint.id);

      if (constraintChild) {
        const constraintParent = state.constraint.list.find(c => c.id === constraintChild.parentId);
        constraintChild.title && results.push(constraintChild.title);
        constraintParent && constraintParent.title && results.push(constraintParent.title);
      }

      if (results.length) {
        results.reverse();
        return results.join(" - ");
      }

      return "";
    });

    const setAnswers = (item, answers) => {
      if (!state.application.answers.new[item.id]) {
        set(state.application.answers.new, item.id, {
          value: arrayItemTypes.includes(item.type) ? [] : null,
          texts: []
        });
      }

      for (let i = 0; i < answers.length; i += 1) {
        if (answers[i].value) {
          if (arrayItemTypes.includes(item.type)) {
            state.application.answers.new[item.id].value.push(answers[i].value);
            ["file"].includes(item.type) && state.application.answers.new[item.id].texts.push(answers[i].fileOriginName);
          } else {
            state.application.answers.new[item.id].value = answers[i].value;
          }
        }
      }
    };

    const load = (callback) => {
      const url = state.application.id
          ? `/api/archives/${state.archive.id}/applications/${state.application.id}`
          : `/api/archives/${state.archive.id}/application`;

      state.application.files = {};

      state.loaded = false;
      http.get(url).then(({data}) => {
        state.application.status = data.body.status;
        state.application.finished = data.body.finished;
        state.application.canceled = data.body.canceled;
        state.application.comment = data.body.comment;
        state.application.mobileAuth = data.body.mobileAuth;
        state.loaded = true;
        state.application.answers.new = {};
        state.application.answers.old = {};
        state.step.list = data.body.steps;

        for (let i = 0; i < state.step.list.length; i += 1) {
          for (let j = 0; j < state.step.list[i].items.length; j += 1) {
            const item = state.step.list[i].items[j];
            state.application.items[item.id] = item;

            setAnswers(item, item.answers);
            state.application.answers.old = lib.getRenewed(state.application.answers.new);

            switch (item.type) {
              case "file":
                set(state.application.files, item.id, []);
                break;
            }
          }
        }

        state.ready = true;
        typeof callback === "function" && callback();
      }).catch(httpError((err) => {
        if (err?.response?.data?.code === "NOT_ALLOWED_PASSPORT") {
          const redirectUrl = window.encodeURIComponent(`/archives/${router.app.$route.params.name}`);
          return store.dispatch("goLoginPage", "login", redirectUrl);
        }

        router.push("/error");
      }));
    };

    const move = (idx) => {
      if (idx < 0) {
        window.alert("첫 페이지 입니다.");
        return;
      }

      query(idx);
    };

    const query = (step) => {
      router.app.$route.query.step !== step?.toString()
      && router.push({query: {step}});
    };

    const upload = async () => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        let hasFile = false;

        for (let i in state.application.files) {
          for (let j in state.application.files[i]) {
            hasFile = true;
            formData.append("files", state.application.files[i][j], `${i}_${state.application.files[i][j].name}`);
          }
        }

        if (!hasFile) {
          resolve();
          return;
        }

        http.post(`/api/archives/${state.archive.id}/applications/${state.application.id}/uploads`, formData).then(({data}) => {
          const itemIds = [];

          data.body.map(d => d.itemId).forEach((id) => {
            !itemIds.includes(id) && itemIds.push(id);
          });

          for (let i = 0; i < itemIds.length; i += 1) {
            data.body.filter(d => d.itemId === itemIds[i]).map(i => i.id).forEach(d => {
              state.application.answers.new[itemIds[i]].value.push(d);
            });
          }

          resolve();
        }).catch(httpError(() => {
          reject();
        }));
      });
    };

    const tell = (item, empty) => {
      const stepIdx = state.step.list.findIndex(s => s.id === item.stepId);

      move(stepIdx);

      setTimeout(() => {
        const idArr = [`pageArchiveStep${item.id + item.type}`];
        ["radio", "checkbox", "agreement", "checklist"].includes(item.type) && idArr.push("0");
        document.getElementById(idArr.join(""))?.focus();
      }, 500);

      const please = optionTypes.includes(item.type)
          ? "선택해주세요"
          : item.type === "file"
              ? "업로드해주세요."
              : "입력해주세요.";

      if (item.hideTitle) {
        window.alert("필수 데이터를 " + please);
        return;
      }

      const suffix = empty ? "을(를) " + please : "의 값이 유효하지 않습니다.";
      window.alert(`${item.title}${suffix}`);
    };

    const isValid = (idx, finished) => {
      const data = [];

      if (finished) {
        state.step.list.forEach(s => {
          s.items.forEach(item => {
            const answer = state.application.answers.new[item.id];
            data.push({item, answer});
          });
        });
      } else {
        state.step.list[idx].items.forEach(item => {
          const answer = state.application.answers.new[item.id];
          data.push({item, answer});
        });
      }

      for (let i = 0; i < data.length; i += 1) {
        const item = data[i].item;
        const answer = data[i].answer;

        if (!item.required) {
          continue;
        }

        if (data[i].answer.value) {
          if (item.type === "email" && !lib.isValidEmail(answer.value)) {
            return tell(item);
          } else if (item.type === "number" && !lib.isNumeric(answer.value)) {
            return tell(item);
          } else if (item.type === "tel" && !lib.isValidPhoneNum(answer.value)) {
            return tell(item);
          } else if (item.type === "personalId" && !answer.value.replaceAll("-", "")) {
            return tell(item);
          }

          if (arrayItemTypes.includes(item.type)) {
            const emptyArray = Array.isArray(answer.value) && !answer.value.length;

            if (item.type === "file") {
              if (!state.application.files[item.id].length && emptyArray) {
                return tell(item, true);
              }
            } else if (emptyArray) {
              return tell(item, true);
            }
          }
        } else {
          return tell(data[i].item, true);
        }
      }

      return true;
    };

    const submit = (finished, isAdmin) => {
      if (finished && !state.application.mobileAuth && !store.state.account.memberAuth.includes("ADMIN")) {
        store.commit("setSwingMessage", "SMS 본인인증이 필요합니다.");
        return;
      }

      if (!store.state.account.memberAuth.includes("ADMIN") && state.application.finished && state.application.status !== "denied") {
        return;
      }

      const run = async () => {
        if (!state.application.id) {
          const res2 = await http.post(`/api/archives/${state.archive.id}/applications`).catch(httpError());

          if (!res2) {
            return;
          }

          state.application.id = res2.data.body;
          await router.replace(`/archives/${state.archive.name}/applications/${state.application.id}?step=${state.step.idx}`);
        }

        await upload();

        if (state.application.finished && isAdmin) {
          finished = true;
        }

        const args1 = {
          finished
        };

        if (state.constraint.id) {
          args1.constraintId = state.constraint.id;
        }

        http.put(`/api/archives/${state.archive.id}/applications/${state.application.id}`, args1).then(() => {
          const args2 = [];

          for (let itemId in state.application.answers.new) {
            const newAnswer = state.application.answers.new[itemId];
            const oldAnswer = state.application.answers.old[itemId];

            if (JSON.stringify(newAnswer) === JSON.stringify(oldAnswer)) {
              continue;
            }

            const push = (value) => {
              args2.push({value: value, itemId});
            };

            if (arrayItemTypes.includes(state.application.items[itemId].type)) {
              if (newAnswer.value.length) {
                newAnswer.value.forEach((value) => push(value));
              } else {

                push(null);
              }
            } else {
              push(newAnswer.value);
            }
          }

          http.put(`/api/archives/${state.archive.id}/applications/${state.application.id}/answers`, args2).then(() => {
            if (finished) {
              window.alert("신청서 제출을 완료하였습니다.");
              return router.replace(`/archives/${state.archive.name}/finished`);
            }

            window.alert("신청서 저장을 완료하였습니다.");
            load();
          });
        });
      };

      if (finished && !isAdmin) {
        if (!isValid(state.step.idx || 0, finished)) {
          return;
        }

        store.commit("confirm", {
          message: "신청서를 제출하시겠습니까?",
          subMessage: "제출하신 후에는 수정이 불가합니다.",
          allowTxt: "제출하기",
          allow() {
            run();
          }
        });
      } else {
        run();
      }
    };

    watch(() => router.app.$route.query.step, () => {
      state.step.idx = window.Number(router.app.$route.query.step) || 0;
      window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    });

    const getUrl = (url) => {
      return url;
    };

    const authorized = (authComplete) => {
      if (authComplete) {
        state.application.mobileAuth = true;
      }
    };

    const updateApplicationId = (id) => {
      state.application.id = id;
    };

    const computedComment = computed(() => {
      return state.application.comment.replace(/\n/g, "<br>");
    });

    return {component, state, computedConstraintLabel, move, submit, query, getUrl, authorized, updateApplicationId, computedComment,};
  }
});
</script>

<style lang="scss" scoped>
.application {
  .card {
    border: 0;
    background: none;

    .card-header {
      background: none;
      border: 0;
      padding: 30px;

      b {
        font-size: 19px;
        font-weight: 900;
      }

      div {
        font-size: $px15;
        padding-top: 7px;

        &.desc {
          color: #a1a1a1;
        }
      }
    }

    .card-body {
      padding: 0;

      .status {
        padding: 30px 25px;
        position: sticky;
        top: 0;
        background: #fff;
        border-bottom: 1px solid #eee;
        z-index: 10;

        > .wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          select {
            height: $px42;
            padding: $px10 $px15;
            width: $px160;
          }

          .side {
            text-align: right;
            font-size: 14px;
          }
        }
      }

      .content {
        > .wrapper {
          padding: $px15 $px55 $px50 $px55;

          .reason {
            font-size: $px13;
            color: $colorDanger;
          }

          .alert {
            margin-top: $px15;
            padding: $px15 $px20;
          }

          .item {
            padding: $px15 0;
          }

          &.mobile-auth {
            padding: $px15 $px55 $px30 $px55;
            border-top: 1px solid #eee;
          }
        }
      }
    }

    .actions {
      position: sticky;
      bottom: 0;
      padding: $px20 $px25;
      background: #fff;
      border-top: 1px solid #eee;

      > .wrapper {
        .buttons {
          margin: 0 -6px;

          > div {
            padding: 0 6px;

            .btn {
              width: 100%;
              padding: $px15 0;
              font-size: $px14;
            }
          }
        }
      }
    }
  }

  @media (min-width: 578px) {
    .card {
      .card-header {
        padding: 45px 60px 5px 60px;
      }

      .card-body {
        .status > .wrapper {
          padding: 0 30px;
        }

        .content > .wrapper {
        }
      }

      .actions > .wrapper {
        padding: $px15 $px30;
      }
    }
  }

  @media (max-width: 576px) {
    .card {
      border: 0;

      .card-header {
        padding-bottom: 0;
      }

      .card-body {
        .status > .wrapper select {
          width: 100%;
        }

        .content > .wrapper {
          padding-left: $px25;
          padding-right: $px25;
        }
      }
    }
  }

  &.skeleton {
    .card {
      .card-header {
        b, div {
          @include skeleton;
        }
      }

      .card-body {
        .status > .wrapper {
          select, .side > span {
            @include skeleton;
          }
        }

        .actions > .wrapper .btn {
          @include skeleton;
        }
      }
    }
  }
}
</style>